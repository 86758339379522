import axios from 'axios';

export default {

    async getResults(terms,sites,start) {
        let excludeSites = '';
        let results = [];
        for(let i=0 ; i<6 ; i++){
            if(!sites[i].state){
                excludeSites += ` ${sites[i].url}`
            }
        }
        results = await this.getResultsFromAPI(terms,excludeSites,start);
        return results;

    },
    async getResultsFromAPI(terms,excludeSites,start){
        let params = {
            key: 'AIzaSyDgjfzOQpoCozJvXkqhpPvryV4S6hvQwfI',
            cx: '014448606063294157758:el7ozjpoeyq',
            q: terms,
            filter: '0',
            start: start,
            excludeTerms: excludeSites,
        };
        return axios({
            method: 'get',
            url: 'https://www.googleapis.com/customsearch/v1/siterestrict/',
            params,
        }).then(response => {
            return response.data;
        })
    },
}
