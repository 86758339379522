import React, {Component} from 'react';
import styled from "styled-components";

import {
    isMobile
} from "react-device-detect";


import {colors} from "../Assets/";

class CheckBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: true
        };
        this.setSelected = this.props.setSelected
    }

    _onCheck() {
        this.setState(function (prevState) {
            return {
                selected: !prevState.selected
            };
        });
        this.props.toggleSite();
    }

    render() {

        const {selected} = this.state;
        const {label} = this.props;

        return (
            <Container>
                <BoxOuter onClick={() => this._onCheck()}>
                    <BoxInner selected={selected}/>
                </BoxOuter>
                <Label className={'inter'} onClick={() => this._onCheck()}>{label}</Label>
            </Container>
        );
    }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
`;

const BoxOuter = styled.button`
  min-width: 24px;
  min-height: 24px;
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: ${isMobile? colors.background: colors.text};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
`;

const BoxInner = styled.div`
  width: 12px;
  height: 12px;
  opacity: 0.8;
  border-radius: 6px;
  background-color: ${props => props.selected? colors.primary : 'transparent'};
`;

const Label = styled.span`
  align-self: center;
  margin-left: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  color: ${isMobile? colors.background : colors.text};
  user-select: none;
  cursor: pointer;
`;


export default CheckBox;
