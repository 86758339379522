import React, {Component} from 'react';
import styled from "styled-components";

// internal
import {colors} from '../Assets';

class ButtonPageNumber extends Component {
    render() {

        const {pressed,number, selected} = this.props;
        return (
            <Container onClick={pressed}>
                <Text selected={selected}>{number}</Text>
            </Container>
        );
    }
}

const Container = styled.button`
  height: 30px;
  width: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primary};
  };
`;

const Text = styled.span`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  color: ${ props => props.selected? colors.primary : colors.background};
  ${Container}:hover & {
    color: ${colors.background};
  };
`;

export default ButtonPageNumber;
