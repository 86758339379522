import React, {Component} from 'react';
import styled from "styled-components";

import {colors} from '../../Assets';
import BigSearchButton from "../BigSearchButton";

class BigSearchInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            bigText: 'rechercher',
            cursorPosition: 0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitAction = this.props.submitAction;
        this.onTextChange = this.props.onTextChange;
    }

    get_tex_size(txt, font) {
        this.element = document.createElement('canvas');
        this.context = this.element.getContext("2d");
        this.context.font = font;
        var tsize = {'width':this.context.measureText(txt).width, 'height':parseInt(this.context.font)};
        return tsize;
    }

    handleChange(event) {
        let bigText = event.target.value === '' ? 'rechercher' : event.target.value;
        var tsize = this.get_tex_size(event.target.value, "600 21px Inter");
        this.setState({
            query: event.target.value,
            bigText: bigText,
            cursorPosition: tsize.width <= 505 ? tsize.width : this.state.cursorPosition,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.submitAction(this.state.query);
    }

    render() {

        const {
            bigText
        } = this.state

        const {
            display
        } = this.props

        return (
            <MainContainer>
                <BigText textSize={display === 'mobile' ? 70 : 100}>{bigText}</BigText>
                <Form
                    onSubmit={this.handleSubmit}>
                    <TextInput
                        type="text"
                        value={this.state.query}
                        onChange={this.handleChange}/>
                    <Cursor position={this.state.cursorPosition}/>
                </Form>
            </MainContainer>
        );
    }
}

const MainContainer = styled.div`
  position: relative;
  margin-left: 20px;
  width: calc(100% - 100px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BigText = styled.p`
  position: absolute;
  color: ${colors.primary};
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  font-size: ${props => props.textSize}px;
  opacity: 0.08;
  text-transform: uppercase;
  user-select: none;
  z-index: 1;
`;

const Form = styled.form`
  height: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
`;

const TextInput = styled.input`
  position: absolute;
  left: 12px;
  height: 100%;
  width: 100%;
  border-width: 0px;
  appearance: none;
  color: transparent;
  background-color: transparent;
  text-shadow: 0 0 0 black;
  text-align: left;
  font-size: 21px;
  font-family: 'Inter';
  font-weight: 600;
  
  ::selection{
    background: ${colors.primary+'66'};
  }
`;

const Cursor = styled.div`
  margin-left: ${p=>p.position+14}px;
  height: 25px;
  width: 4px;
  left: 12px;
  top: 0px;
  background-color: ${colors.primary};
  opacity: 0.8;
  animation: blink 1s linear infinite;
`;

export default BigSearchInput;
