import React, {Component} from 'react';
import styled, {css} from "styled-components";
import {colors} from "../../Assets";

class MobileAboutView extends Component {
    render() {
        const {
            isOpen
        } = this.props;
        return (
            <MainView isOpen={isOpen}>
                <TextAbout isOpen={isOpen}>
                    <P className={'gela'}>Calvini est un moteur de recherche, qui permet d’effectuer des requêtes parmi les articles de
                        plusieurs blogs chrétiens évangéliques qui partagent une ligne théologique similaire:
                        <Link href="https://evangile21.thegospelcoalition.org/" target="_blank"> Évangile 21</Link>,
                        <Link href="https://www.leboncombat.fr" target="_blank"> Le Bon Combat</Link>,
                        <Link href="https://www.larebellution.com/" target="_blank"> La Rébellution</Link>,
                        <Link href="https://www.reveniralevangile.com/" target="_blank"> Revenir à l’Évangile</Link>,
                        <Link href="https://toutpoursagloire.com/" target="_blank"> Tout Pour Sa Gloire </Link>et
                        <Link href="https://www.unherautdansle.net/" target="_blank"> Un Héraut dans le Net</Link>.</P>
                    <P className={'gela'}>Bien que nous partagions la même sensibilité, chaque auteur de chaque site est responsable de ses écrits.</P>
                    <P className={'gela'}>Sur le plan technique, Calvini fonctionne avec l’API de Google, l’affichage des résultats ne dépend donc pas du moteur en lui-même.</P>
                </TextAbout>
                <ContactView>
                    <ContactRow className={'gela'}>Mascotte: <Link href="https://www.arnokorper.com/" target="_blank">www.arnokorper.com</Link></ContactRow>
                    <ContactRow className={'gela'}>Site: <Link href="mailto: david.perrey@gmail.com">david.perrey@gmail.com</Link></ContactRow>
                </ContactView>
                <CloseButton className={'inter'} onClick={this.props.onPressClose}>FERMER</CloseButton>
            </MainView>
        );
    }
}

const MainView = styled.div`
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: ${colors.dark};
    visibility: ${props => props.isOpen? 'visible' : 'hidden'};
`;

const TextAbout = styled.div`
    position: relative;
    width: calc(100vw - 60px);
    transition-duration: 0.6s;
    transition-property: opacity;
    opacity: ${props => props.isOpen ? 1 : 0};
`;

const P = styled.p`
    font-weight: 500;
    line-height: 150%;
    font-size: 17px;
    color: ${colors.background};
`;

const Link = styled.a`
  color: ${colors.primary};
`;

const CloseButton = styled.button`
  height: 40px;
  margin-top: 30px;
  background-color: transparent;
  border: none;
  font-size: 17px;
  font-weight: 500;
  color: ${colors.background};
  text-decoration: underline;
`;

const ContactView = styled.div`
    position:relative;
    margin-top: 32px;
    width: calc(100vw - 60px);
`;

const ContactRow = styled.p`
    font-weight: 500;
    line-height: 150%;
    font-size: 17px;
    color: ${colors.background};
    margin-top: 4px;
    margin-bottom: 0px;
`;

export default MobileAboutView;
