import React, {Component} from 'react';
import styled from "styled-components";

import logo from "../Assets/calvini_logo.svg";
import {colors} from "../Assets";

class Title extends Component {
    render() {
        const {
            isBig
        } = this.props;
        return (
            <MainView isBig={isBig}>
                <Logo src={logo} isBig={isBig}></Logo>
                <Text className={"gara"} isBig={isBig}>Calvini</Text>
            </MainView>
        );
    }
}

const MainView = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${props => props.isBig? 0 : 0}px;
  margin-top:  ${props => props.isBig? 25 : 10}vh;
  transition: 0.6s;
`;

const Text = styled.span`
  text-align: left;
  font-size: ${props => props.isBig ? 70 : 40}px;
  color: ${props => props.isBig ? colors.text : colors.text};
  transition: 0.6s;
`;

const Logo = styled.img`
  width: ${props => props.isBig ? 110 : 70}px;
  height: ${props => props.isBig ? 110 : 70}px;
  margin-right: 32px;
  margin-left: ${props => props.isBig ? 0 : 4}vw;
  transition: 0.6s;
`;

export default Title;
