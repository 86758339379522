export default {
    get_tex_size(txt, font) {
        let element = document.createElement('canvas');
        let context = element.getContext("2d");
        context.font = font;
        return {'width': context.measureText(txt).width, 'height':parseInt(context.font)};
    },
    betterDisplayLink(link) {
        return link.replace(/www\./, '')
            .replace(/\.thegospelcoalition/, '')
            .replace(/(\.com|\.fr|\.org|)/g,'');
    },
}

