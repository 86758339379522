import React, {Component} from 'react';
import styled, {css} from "styled-components";

import HomeSearchBar from "../Components/Mobile/HomeSearchBar";
import BottomBar from "../Components/Mobile/BottomBar";
import MobileList from "../Components/Mobile/MobileList";

import SearchHeader from "../Components/Mobile/SearchHeader";
import {colors} from "../Assets";
import gcs from "../Services/gcs";

import logologo from '../Assets/calvini_logo.svg';
import logo from '../Assets/calvini_coming_soon.svg';

class MobileScreen extends Component {

    constructor(props) {
        super(props);
        this.state ={
            query: '',
            smallScreen: false,
            listState: 0,
            page: 1,
            unlocked: 0,
            nextPageExist: false,
            searchResult: [],
            visibleHeader: false,
            locked: true,
        };
        this.sites = [
            {url: 'evangile21.thegospelcoalition.org', name: 'evangile21', state: true},
            {url: 'larebellution.com', name: 'larebellution', state: true},
            {url: 'leboncombat.fr', name: 'leboncombat', state: true},
            {url: 'reveniralevangile.com', name: 'reveniralevangile', state: true},
            {url: 'toutpoursagloire.com', name: 'toutpoursagloire', state: true},
            {url: 'unherautdansle.net', name: 'unherautdanslenet', state: true},
        ];

        this.pages = [];
    }


    initPages(totalPages) {
        this.pages = [];
        let numberOfPages = totalPages
        if(totalPages >= 5) numberOfPages = 5;
        for(let i=0 ; i < numberOfPages ; i++) {
            this.pages.push(i+1);
        }
    }

    // listState: 0 -> la liste n'est pas visible
    // listState: 1 -> la liste est visible
    // listState: 2 -> la liste est rechargée (nouvelle page ou nouvelle requête)
    // listState: 3 -> la liste avec les nouveaux items apparaît.
    // listState: 5 -> liste vide

    async _getResults(query) {
        this.setState({query: query}, () => {
            this.setState({
                visibleHeader: true,
                listState: 2,
                page: 1
            })
        });
        let res = await gcs.getResults(query, this.sites, 1);
        this.setState({totalResults: res.searchInformation.totalResults});
        // initialisation de la liste des pages disponibles calculé en fonction
        // du nombre total de résultats
        this.initPages(Math.round(res.searchInformation.totalResults/10));

        let noRes = res.searchInformation.totalResults === '0' ? true : false;
        this.setState({
            searchResult: res.items ? [...res.items] : [],
            listState: noRes? 5 : 1,
            nextPageExist: !!res.queries.nextPage,
        });
    }

    _toggleSite(siteId) {
        this.sites[siteId].state = !this.sites[siteId].state;
        this._getResults(this.state.query)
    }

    async _onPagePressed(page) {
        // la liste monte
        // this._scrollToTop();
        this.setState({listState: 2, page: page});
        // on lance la requête au bout de 0.5s afin de laisser la première partie de l'animation
        // se terminer.
        let timeout1 = setTimeout(this._getNewPage.bind(this),500);
    }

    async _getNewPage() {
        let start = this.state.page === 1 ? 1 : (this.state.page-1) * 10;

        this.updatePagesList(this.state.page);
        // la liste est placée en bas
        this.setState({listState: 3});
        let res = await gcs.getResults(this.state.query, this.sites, start);
        // on lance la dernière partie de l'animation dès qu'on obient un réponse
        this.setState({
            listState: 1,
            searchResult: [...res.items],
            nextPageExist: !!res.queries.nextPage,
        });
    }

    updatePagesList(pagePressed) {
        // première condition: nombre de pages > 4
        if(this.pages.length > 4){
            // 1er cas de changement: l'utilisateur à cliqué sur la dernière ou l'avant dernière page proposée
            if(pagePressed >= this.pages[3]) {
                // si on peut ajouter une page
                if(this.state.totalResults/10 > this.pages[4]) {
                    // on augmente chacun des éléments de la liste de 1
                    for(let i=0 ; i < 5 ; i++){
                        this.pages[i] = this.pages[i]+1;
                    }
                }
            }
            // 2eme cas de changement: l'utilisateur a cliqué sur la première ou la deuxième page proposée
            if(pagePressed <= this.pages[1]) {
                // si nous ne sommes pas déjà au début de la liste
                if(this.pages[0] !== 1) {
                    // on diminu chacun des éléments de la liste de 1
                    for(let i=0 ; i < 5 ; i++){
                        this.pages[i] = this.pages[i]-1;
                    }
                }
            }
        }
    }

    renderPages() {
        return (
            <PageSelector>
                {this.state.page !== 1 &&
                <PageArrow onClick={() => this._onPagePressed(this.state.page-1)}>{'< précédente'}</PageArrow>
                }
                <Pages>
                    {this.pages.map((item, index) => (
                        <PageButton
                            key={index}
                            selected={this.pages[index] === this.state.page}
                            onClick={() => this._onPagePressed(this.pages[index])}>
                            {this.pages[index]}
                        </PageButton>
                    ))}
                </Pages>
                { this.state.totalResults/10 > this.state.page &&
                <PageArrow onClick={() => this._onPagePressed(this.state.page+1)}>{'suivante >'}</PageArrow>
                }
            </PageSelector>
        )
    }

    _goHome() {
        this.setState({listState: 0});
    }


    render() {

        const {
            listState,
            searchResult,
            page,
            nextPageExist,
            query,
            visibleHeader,
        } = this.state;

        return (
            <ScreenView>

                <FlexView
                    visible={listState === 0}>
                    <LogoTitle visible={listState === 0}>
                        <LogoLogo src={logologo}/>
                        <Title
                            className={'gara'}
                            visible={listState === 0}>
                            Calvini
                        </Title>
                    </LogoTitle>

                    <HomeSearchBar
                        visible={listState === 0}
                        submitAction={this._getResults.bind(this)}
                    />
                    <Desc
                        visible={listState === 0}>
                        Calvini est un moteur de recherche, qui permet d’effectuer des requêtes parmi les articles de plusieurs blogs chrétiens évangéliques qui partagent une ligne théologique similaire                    </Desc>
                </FlexView>

                {listState !== 0 &&
                    <View
                        visible={listState !== 0}>
                        <SearchHeader
                            visible={visibleHeader}
                            firstQuery={query}
                            submitAction={this._getResults.bind(this)}
                            listState={listState}/>
                        <MobileList
                            pagesComponent={this.renderPages.bind(this)}
                            listState={listState}
                            items={searchResult}/>
                        <BottomBar
                            page={page}
                            nextPageExist={nextPageExist}
                            pressHome={this._goHome.bind(this)}
                            toggleSite={this._toggleSite.bind(this)}
                            onPagePressed={this._onPagePressed.bind(this)}
                            visible={listState !== 0}/>
                    </View>
                }

                {/*{ this.state.unlocked < 7 &&*/}
                {/*    <ComingSoon>*/}
                {/*        <Logo src={logo} onClick={() => {this.setState({ unlocked: this.state.unlocked+1})}}/>*/}
                {/*    </ComingSoon>*/}
                {/*}*/}

            </ScreenView>
        );
    }
}

const ScreenView = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.background};
`;

const View = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    overflow: hidden;
`;

const FlexView = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
`;

const LogoTitle = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: 0.4s;
    ${props => props.visible ?
        css`transform: translate3d(0);`
        :
        css`transform: translate3d(-120vw,0px,0px);`
    };
    margin: 30px 30px 0 30px;
`;

const LogoLogo = styled.img`
  height: 90px;
  width: 90px;
`;

const Title = styled.p`
    font-size: 45px;
    margin-left: 16px;
    color: ${colors.text};
    font-weight: 800;
`;

const Desc = styled.p`
    color: ${colors.text};
    font-family: Gelasio, serif;
    font-size: 5vw;
    line-height: 150%;
    font-weight: 500;
    margin: 30px 30px 0 30px;
    transition: 0.4s
    ;
    ${props => props.visible ?
        css`transform: translate3d(0);`
        :
        css`transform: translate3d(0px,100vh,0px);`
    };
`;

const PageSelector = styled.div`
  margin-bottom: 32px;
  margin-left: 4vw;
  margin-right: 4vw;
  width: 92vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Pages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const PageButton = styled.button`
  height: 25px;
  width: 25px;
  color: ${ props => props.selected ? colors.primary : colors.text};
  background-color: ${colors.background};
  font-family: Inter;
  font-weight: ${ props => props.selected ? 700 : 500};
  font-size: 15px;
  border: none;
  cursor: pointer;
  :hover{
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const PageArrow = styled.span`
  color: ${colors.text};
  font-family: Inter;
  font-weight: 500;
  font-size: 15px;
    :hover{
    color: ${colors.primary};
  }
  cursor:pointer;
`;

const ComingSoon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
   z-index: 999;
`;


const Logo = styled.img`
  width: 40vh;
`;

const CheatInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  opacity: 0.01%;
  background-color: ${colors.background};
  color: ${colors.background};
`;


export default MobileScreen;
