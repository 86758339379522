import React, {Component} from 'react';
import styled, {css} from "styled-components";

import {colors} from "../Assets";

class HeaderDesc extends Component {
    render() {
        const {
            visible
        } = this.props;
        return (
            <Text visible={visible} className={"gela"}>
                Calvini est un moteur de recherche, qui permet d’effectuer des requêtes parmi les articles de plusieurs blogs chrétiens évangéliques qui partagent une ligne théologique similaire.</Text>
        );
    }
}

const Text = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  color: ${colors.text};
  text-align: left;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  
  ${props => props.visible ?
        css`transform: translate3d(0); opacity: 1`
        :
        css`transform: translate3d(0px,400px,0px); opacity: 0`
    };
    transition: 0.5s;
    
    //background-color: #61dafb;
`;

export default HeaderDesc;
