import React from 'react';
import {
    BrowserView,
    MobileView,
} from "react-device-detect";

import './App.css';

import MainScreen from "./Screens/MainScreen";
import MobileScreen from "./Screens/MobileScreen";

function App() {
  return (
    <div className="App">
        <MobileView>
            <MobileScreen/>
        </MobileView>
        <BrowserView>
            <MainScreen/>
        </BrowserView>
    </div>
  );
}

export default App;
