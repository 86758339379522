import React, {Component} from 'react';
import styled from "styled-components";

import {colors} from '../Assets';

class SnippetCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opacity: 0,
            marginLeft: -320,
        }
    }

    componentDidMount() {
        let timer = setTimeout(() => {
            this.setState({
                opacity: 1,
                marginLeft: -340,
            });
        }, 10);
    }

    render() {

        const {
            snippet,
            coverSrc,
            mousePos,
        } = this.props;

        const {
            opacity,
            marginLeft
        } = this.state;

        const move = (window.innerHeight - mousePos) < 310;

        return (
            <Container opacity={opacity} marginLeft={marginLeft} isTooLow={move}>
                <CardImage src={coverSrc}/>
                <CardTopBackground/>
                <CardTitle>APERÇU</CardTitle>
                <CardContent>{snippet}</CardContent>
            </Container>
        );
    }
}

const Container = styled.div`
    position: absolute;
    width: 280px;
    height: 310px;
    text-align: left;
    background-color: ${colors.dark};
    transition: 0.5s;
    opacity: ${ p => p.opacity};
    margin-left: ${ p => p.marginLeft}px;
    top: ${ props => props.isTooLow ? -230 : 0}px;
    //box-shadow: 0px 7px 60px -21px ${colors.primary+'aa'};
    z-index: 1001;
`;

const CardImage = styled.img`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 45%;
    //filter: grayscale(100%);
    object-fit: cover;
 `;

const CardTopBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 45%;
  width: 280px;
  background-color: ${colors.primary};
  opacity: 0.0;
`;

const CardTitle = styled.p`
  position: absolute;
  top: 160px;
  font-family: 'Inter';
  font-size: 17px;
  font-weight: 700;
  color: ${colors.background};
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
`;

const CardContent = styled.p`
  position: absolute;
  top: 180px;
  width: 242px;
  font-family: 'PT Serif';
  line-height: 140%;
  font-size: 14px;
  color: ${colors.background};
  margin-left: 20px;
  margin-right: 20px;
`;

export default SnippetCard;
