import React, {Component} from 'react';
import styled from "styled-components";

import {colors} from '../../Assets'


class MobileCard extends Component {
    render() {
        const {
            title,
            domain,
            link,
        } = this.props;

        return (
            <MainContainer>
                <Title target="_blank" rel="noopener noreferrer" href={link}>
                    {title}
                </Title>
                <Url>{domain.toUpperCase()}</Url>
            </MainContainer>
        );
    }
}

const MainContainer = styled.div`
  margin-left: 4vw;
  margin-right: 4vw;
  width: 92vw;
  margin-bottom: 4vh;
`;

const Title = styled.a`
  text-align: inherit;
  font-family: Gelasio, serif;
  font-weight: 500;
  //font-size: 1.2em;
  font-size: 4.7vw;
  margin-top: 20px;
  margin-bottom: 0;
  text-decoration: none;
  :visited{
  opacity: 0.7;
  text-decoration: none;
    };
`;

const Url = styled.p`
  text-align: left;
  font-family: Inter, sans-serif;
  font-weight: 500;
  color: ${colors.text};
  letter-spacing: 1.29999995231628px;
  opacity: 0.4;
  font-size: 3vw;
  margin-top: 6px;
  margin-bottom: 20px;
  overflow: hidden;
`;

export default MobileCard;
