import React, {Component} from 'react';
import styled, {css} from "styled-components";

import BigSearchButton from "../BigSearchButton";
import BigSearchInput from "./BigSearchInput";
import {colors} from "../../Assets";

class HomeSearchBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            bigText: 'rechercher',
            cursorPosition: 0,
            cursorVisible: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitAction = this.props.submitAction;
        this.onTextChange = this.props.onTextChange;
    }

    _onFocus() {
        this.setState({cursorVisible: false})
    }

    get_tex_size(txt, font) {
        this.element = document.createElement('canvas');
        this.context = this.element.getContext("2d");
        this.context.font = font;
        var tsize = {'width':this.context.measureText(txt).width, 'height':parseInt(this.context.font)};
        return tsize;
    }

    handleChange(event) {
        let bigText = event.target.value === '' ? 'rechercher' : event.target.value;
        var tsize = this.get_tex_size(event.target.value, "500 21px Inter");
        this.setState({
            query: event.target.value,
            bigText: bigText,
            cursorPosition: tsize.width <= 505 ? tsize.width : this.state.cursorPosition,
        });
    }

    handleSubmit(event) {
        document.getElementById('input').blur();
        event.preventDefault();
        this.submitAction(this.state.query);
    }

    render() {
        const {
            display,
            visible,
        } = this.props;

        const {
            bigText
        } = this.state;

        return (
            <MainContainer visible={visible}>
                <FormContainer>
                    {/*<BigText textSize={display === 'mobile' ? 70 : 100}>{bigText}</BigText>*/}
                    <Form onSubmit={this.handleSubmit}>
                        <TextInput
                            id={'input'}
                            type="text"
                            value={this.state.query}
                            placeholder={'rechercher'}
                            onFocus={this._onFocus.bind(this)}
                            onChange={this.handleChange}/>
                        {this.state.cursorVisible && <Cursor position={this.state.cursorPosition} visible={this.state.cursorVisible}/>}
                    </Form>
                </FormContainer>
                <BigSearchButton action={this.handleSubmit} size={70}/>
            </MainContainer>
        );
    }
}

const MainContainer = styled.div`
    width: 100vw;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    transition: 0.3s
    ;
    ${props => props.visible ?
        css`opacity: 1;`
        :
        css`opacity: 0;`
    }
`;

const FormContainer = styled.div`
  position: relative;
  margin-left: 30px;
  margin-right: 42px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Form = styled.form`
  height: 40px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 2.5px solid ${colors.dark};
  z-index: 2;
`;

const TextInput = styled.input`
  position: absolute;
  height: 40px;
  width: 85%;
  appearance: none;
  background-color: transparent;
  //text-shadow: 0 0 0 black;
  text-align: left;
  color: black;
  caret-color: ${colors.primary};
  font-size: 21px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  text-decoration: none;
  border: none;
  ::selection{
    background: ${colors.primary+'66'};
  }
`;

const Cursor = styled.div`
  margin-left: ${p=>p.position}px;
  height: 25px;
  width: 3px;
  top: 0;
  background-color: ${colors.primary};
  animation: blink 1s linear infinite;
`;


const BigText = styled.p`
  position: absolute;
  color: ${colors.primary};
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -30px;
  text-align: left;
  font-family: Inter, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 70px;
  opacity: 0.08;
  text-transform: uppercase;
  user-select: none;
  z-index: 1;
`;

export default HomeSearchBar;
