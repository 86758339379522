import React, {Component} from 'react';
import styled, {css} from "styled-components";

import gcs from '../Services/gcs';
import {colors} from '../Assets';
import {Title} from '../Components';
import HeaderDesc from "../Components/HeaderDesc";
import SearchBar from "../Components/SearchBar";
import ListView from "../Components/ListView";
import ButtonPageNumber from "../Components/ButtonPageNumber";
import CheckBox from "../Components/CheckBox";

import logo from "../Assets/calvini_coming_soon.svg";

import logoTitle from "../Assets/calvini_coming_soon.svg";
import AboutView from "../Components/AboutView";


class MainScreen extends Component {

    constructor(props) {
        super(props);
        this.state ={
            query: '',
            listActive: false,
            smallScreen: false,
            searchResults: [],
            listState: 0,
            aboutIsOpen: false,
            page: 1,
            totalResults: 0,
            nextPageExist: false,
            unlocked: false,
            unlockStep: 0,
            audioIsPLaying: false,
        }
        this.sites = [
            {url: 'evangile21.thegospelcoalition.org', name: 'evangile21', state: true},
            {url: 'larebellution.com', name: 'larebellution', state: true},
            {url: 'leboncombat.fr', name: 'leboncombat', state: true},
            {url: 'reveniralevangile.com', name: 'reveniralevangile', state: true},
            {url: 'toutpoursagloire.com', name: 'toutpoursagloire', state: true},
            {url: 'unherautdansle.net', name: 'unherautdanslenet', state: true},
        ];
        this.pages = [];
        this.cheatString = '';
        this.konamiCodeFunction = this.konamiCodeFunction.bind(this);
    }

    konamiCodeFunction(event){
        if(!this.state.unlocked) {
            switch(event.key) {
                case 's' : this.cheatString += 'e'; break
                case 'ArrowUp' : this.cheatString += 'u'; break;
                case 'ArrowDown' : this.cheatString += 'd'; break;
                case 'ArrowLeft' : this.cheatString += 'l'; break;
                case 'ArrowRight' : this.cheatString += 'r'; break;
                case 'a' : this.cheatString += 'a'; break;
                case 'b' : this.cheatString += 'b'; break;
            }

            if(this.cheatString.length >= 2) {
                console.log(this.cheatString);
                if(this.cheatString.slice(this.cheatString.length - 2, this.cheatString.length) === 'uu'){
                    this.setState({unlockStep: 1});
                }
            }

            if(this.state.unlockStep === 1 && !this.state.audioIsPlaying) {
                // var audio = new Audio(soundfile);
                // audio.play();
                // this.setState({audioIsPlaying: true})
            }

            if(this.cheatString.length >= 4) {
                if(this.cheatString.slice(this.cheatString.length - 4, this.cheatString.length) === 'uudd'){
                    this.setState({unlockStep: 2});
                }
            }

            if(this.cheatString.length >= 6) {
                if(this.cheatString.slice(this.cheatString.length - 6, this.cheatString.length) === 'uuddlr'){
                    this.setState({unlockStep: 3});
                }
            }

            if(this.cheatString.length >= 8) {
                if(this.cheatString.slice(this.cheatString.length - 8, this.cheatString.length) === 'uuddlrlr'){
                    this.setState({unlockStep: 4});
                }
            }

            if(this.cheatString.length >= 10) {
                if(this.cheatString.slice(this.cheatString.length - 10, this.cheatString.length) === 'uuddlrlrba'){
                    this.setState({unlockStep: 5});
                }
            }

            if(this.cheatString.length >= 11) {
                if(this.cheatString.slice(this.cheatString.length - 11, this.cheatString.length) === 'uuddlrlrbae'){
                    this.setState({unlocked: true});
                    alert('full power unlocked')
                }
            }
        }

    }

    componentDidMount(){
        // document.addEventListener("keydown", this.konamiCodeFunction, false);
    }
    async _getResults(query) {
        this._scrollToTop();
        this.setState({listState: 1});
        let res = await gcs.getResults(query, this.sites, 1);
        this.setState({totalResults: res.searchInformation.totalResults});
        // initialisation de la liste des pages disponibles calculé en fonction
        // du nombre total de résultats
        this.initPages(Math.round(res.searchInformation.totalResults/10));

        let noRes = res.searchInformation.totalResults === '0' ? true : false;
        this.setState({
            page: 1,
            query: query,
            searchResults: res.items ? [...res.items] : [],
            listState: noRes? 5 : 1,
            nextPageExist: res.queries.nextPage ? true : false,
        });
    }

    initPages(totalPages) {
        this.pages = [];
        let numberOfPages = totalPages
        if(totalPages >= 5) numberOfPages = 5;
        for(let i=0 ; i < numberOfPages ; i++) {
            this.pages.push(i+1);
        }
    }

    _scrollToTop() {
        var scrollViewElement = document.querySelector('.scroll-list');
        scrollViewElement.scrollTo({
            top: 0,
            left: 0,
        })
    }

    async _onPagePressed(page) {
        if(page === this.state.page) return;
        // la liste monte
        this._scrollToTop();
        this.setState({listState: 2, page: page,})
        // on lance la requête au bout de 0.5s afin de laisser la première partie de l'animation
        // se terminer.
        let timeout1 = setTimeout(this._getNewPage.bind(this),500);
    }
    async _getNewPage() {
        let start = this.state.page === 1 ? 1 : (this.state.page-1) * 10;
        // mise à jour de l'afifchage des pages
        this.updatePagesList(this.state.page);
        // la liste est placée en bas
        this.setState({listState: 3})
        let res = await gcs.getResults(this.state.query, this.sites, start);
        // on lance la dernière partie de l'animation dès qu'on obient un réponse
        this.setState({
            listState: 1,
            searchResults: [...res.items],
            nextPageExist: res.queries.nextPage ? true : false,
        });
    }

    updatePagesList(pagePressed) {
        // première condition: nombre de pages > 4
        if(this.pages.length > 4){
            // 1er cas de changement: l'utilisateur à cliqué sur la dernière ou l'avant dernière page proposée
            if(pagePressed >= this.pages[3]) {
                // si on peut ajouter une page
                if(this.state.totalResults/10 > this.pages[4]) {
                    // on augmente chacun des éléments de la liste de 1
                    for(let i=0 ; i < 5 ; i++){
                        this.pages[i] = this.pages[i]+1;
                    }
                }
            }
            // 2eme cas de changement: l'utilisateur a cliqué sur la première ou la deuxième page proposée
            if(pagePressed <= this.pages[1]) {
                // si nous ne sommes pas déjà au début de la liste
                if(this.pages[0] !== 1) {
                    // on diminu chacun des éléments de la liste de 1
                    for(let i=0 ; i < 5 ; i++){
                        this.pages[i] = this.pages[i]-1;
                    }
                }
            }
        }
    }

    _toggleSite(siteId) {
        this.sites[siteId].state = !this.sites[siteId].state;
        this._getResults(this.state.query)
    }

    renderPages() {
        return (
            <PageSelector>
                {this.state.page !== 1 &&
                    <PageArrow onClick={() => this._onPagePressed(this.state.page-1)}>{'< précédente'}</PageArrow>
                }
                <Pages>
                    {this.pages.map((item, index) => (
                        <PageButton
                            key={index}
                            selected={this.pages[index] === this.state.page}
                            onClick={() => this._onPagePressed(this.pages[index])}>
                            {this.pages[index]}
                        </PageButton>
                    ))}
                </Pages>
                { this.state.totalResults/10 > this.state.page &&
                    <PageArrow onClick={() => this._onPagePressed(this.state.page+1)}>{'suivante >'}</PageArrow>
                }
            </PageSelector>
        )
    }

    _toggleAbout() {
        this.setState(function (prevState) {
            return {
                aboutIsOpen: !prevState.aboutIsOpen,
            };
        });
    }

    render() {

        const {
            listState,
            smallScreen,
            searchResults,
            page,
            nextPageExist,
            pageTransitionState,
        } = this.state

        return (
            <MainView>

                <SearchView isBig={listState === 0}>
                    {/* TITLE */}
                     <Title isBig={listState === 0}></Title>
                    {/* SEARCH BAR*/}
                    <SearchBar
                        listState={listState === 0}
                        submitAction={this._getResults.bind(this)}
                    />
                    {/* CHECKBOX */}
                    <Row>
                        <HeaderDesc
                            visible={listState === 0}></HeaderDesc>
                        <SitesSelection
                            visible={listState > 0}>
                            {this.sites.map((item, index) => (
                                <CheckBox label={item.name} toggleSite={this._toggleSite.bind(this,index)}/>
                            ))}
                        </SitesSelection>
                    </Row>
                </SearchView>

                <AboutButton
                    visible={listState !== 0}
                    isOpen={this.state.aboutIsOpen}
                    onClick={() => {this._toggleAbout()}}>{this.state.aboutIsOpen? 'Fermer X' : 'À propos'}</AboutButton>
                <AboutView isOpen={this.state.aboutIsOpen}/>

                <ListView
                    listState={listState}
                    items={searchResults}
                    pagesComponent={this.renderPages.bind(this)}/>


                {/*{!this.state.unlocked &&*/}
                {/*    <ComingSoon>*/}
                {/*        <Logo src={logo}/>*/}
                {/*        <KonamiView visible={this.state.unlockStep >=1}>*/}
                {/*            <Gif src={'https://media.giphy.com/media/14uRnBOrCwkOMU/giphy.gif'} alt={'animated'} x={0} y={70} visible={this.state.unlockStep >= 5}/>*/}
                {/*            <Gif src={'https://media.giphy.com/media/bkzDZMBnSZLPi/giphy.gif'} alt={'animated'} x={20} y={60} visible={this.state.unlockStep >= 2}/>*/}
                {/*            <Gif src={'https://media.giphy.com/media/3o7btYF2V0JoNgC9fa/giphy.gif'} alt={'animated'} x={5} y={10} visible={this.state.unlockStep >= 3}/>*/}
                {/*            <Gif src={'https://media.giphy.com/media/xT1XGPm6RZ01MvmtNK/giphy.gif'} alt={'animated'} x={60} y={50} visible={this.state.unlockStep >= 4}/>*/}
                {/*            <Gif src={'https://media.giphy.com/media/zflm4EZ5eRrnG/giphy.gif'} alt={'animated'} x={60} y={10} visible={this.state.unlockStep >= 3}/>*/}
                {/*            <Gif src={'https://media.giphy.com/media/13ki5hgynlBaFy/giphy.gif'} alt={'animated'} x={30} y={30} visible={this.state.unlockStep >= 1}/>*/}
                {/*        </KonamiView>*/}
                {/*    </ComingSoon>*/}
                {/*}*/}
            </MainView>
        );
    }
}

const MainView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${colors.background};
  overflow: hidden;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SearchView = styled.div`
    transition: 0.8s;
    height: 100vh;
    width: 540px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.isBig ? colors.background : colors.background};
`;

const Row = styled.div`
  position: relative;
  margin-top: 2vh;
`;

const SitesSelection = styled.div`
    display: flex;
    flex-direction: column;
    ${props => props.visible ?
        css`transform: translate3d(0); opacity: 1; margin-left: 4vw`
        :
        css`transform: translate3d(-400px,0px,0px); opacity: 0; margin-left: 0vw`
    };
    transition: 0.8s;
`;

const AboutButton = styled.span`
  position: fixed;
  bottom: 8vh;
  left: 4vw;
  font: Inter, sans;
  font-weight: 600;
  letter-spacing: 1.3px;
  color: ${props => props.isOpen ? colors.background : colors.text};
  visibility: ${props => props.visible? 'visible' : 'hidden'};
  text-transform: uppercase;
  cursor: pointer;
  :hover{
    text-decoration: underline;
  }
  z-index: 1000;
`;

const PageSelector = styled.div`
  margin-bottom: 8vh;
  margin-top: 32px;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

`;

const Pages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
`;

const PageButton = styled.button`
  height: 30px;
  width: 30px;
  color: ${ props => props.selected ? colors.primary : colors.text};
  background-color: ${colors.background};
  font-family: Inter;
  font-weight: ${ props => props.selected ? 700 : 500};
  font-size: 15px;
  border: none;
  cursor: pointer;
  :hover{
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const PageArrow = styled.span`
  color: ${colors.text}
  font-family: Inter;
  font-weight: 500;
  font-size: 15px;
    :hover{
    color: ${colors.primary};
  }
  cursor:pointer;
`;

const KonamiView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 999;
  visibility: ${props => props.visible? 'visible' : 'hidden'};
`;

const Gif = styled.img`
    position: absolute;
    top: ${props => props.y}%;
    left: ${props => props.x}%;
    background-color: #FFFCF8;
    min-height: 300px;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;

const ComingSoon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
   z-index: 999;
`;

const Logo = styled.img`
  width: 40vh;
`;


export default MainScreen;
