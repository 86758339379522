import React, {Component} from 'react';
import styled, {css} from "styled-components";
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import Burger from 'react-css-burger';

import {colors} from '../../Assets';
import CheckBox from "../CheckBox";
import MobileAboutView from "./MobileAboutView";

class BottomBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            aboutIsOpen: false
        }
    }
    _toggleBS() {
        this.setState(function (prevState) {
            return {
                open: !prevState.open,
            };
        });
    }

    _toggleAbout() {
        this.setState(function (prevState) {
            return {
                aboutIsOpen: !prevState.aboutIsOpen,
            };
        });
    }

    _onTransitionEnd() {
        console.log('have end transition');
    }

    _onChange(isOpen) {
        this.setState({open: isOpen})
    }

    render() {

        const {
            open,
            aboutIsOpen
        } = this.state;

        const {
            page,
            nextPageExist,
            onPagePressed,
            visible,
            toggleSite,
        } = this.props;

        return (
            <BB>
                <SwipeableBottomSheet
                    overflowHeight={75}
                    open={this.state.open}
                    shadowTip={false}
                    topShadow={false}
                    onChange={this._onChange.bind(this)}>
                    <BSContent
                        visible={visible}>
                        <SwipeIndicator></SwipeIndicator>
                        <SiteSelector>
                            <CheckBox label={'evangile21'} toggleSite={() => toggleSite(0)}/>
                            <CheckBox label={'larebellution'} toggleSite={() => toggleSite(1)}/>
                            <CheckBox label={'leboncombat'} toggleSite={() => toggleSite(2)}/>
                            <CheckBox label={'reveniralevangile'} toggleSite={() => toggleSite(3)}/>
                            <CheckBox label={'toutpoursagloire'} toggleSite={() => toggleSite(4)}/>
                            <CheckBox label={'unherautdanslenet'} toggleSite={() => toggleSite(5)}/>
                        </SiteSelector>
                    </BSContent>
                </SwipeableBottomSheet>

                <Burger
                    style={styles.burgerStyle}
                    onClick={() => this._toggleBS()}
                    active={this.state.open}
                    burger="squeeze"
                    color={this.state.open? colors.background : colors.background }
                    hoverOpacity={1}
                    scale={0.7}
                    marginTop={5}
                    marginBottom={0}
                />
                <SmallTitle visible={!this.state.open} className={'gara'}>Calvini</SmallTitle>
                <AboutButton visible={this.state.open} className={'inter'} onClick={() => this._toggleAbout()}>À PROPOS</AboutButton>
                <MobileAboutView isOpen={this.state.aboutIsOpen} onPressClose={() => this._toggleAbout()}/>
            </BB>

        );
    }
}

const styles = {
    BottomSheet:{
        backgroundColor: colors.dark,
    },
    burgerStyle:{
        position: 'fixed',
        bottom: '5px',
        left: '0px',
        marginLeft: '10px',
        width: '40px',
        height: '40px'
    }
};

const BB = styled.div`
`;

const FixedContent = styled.div`
    background-color: ${colors.dark};
    position: fixed;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    transition: 0.4s;
    transition-delay: 0.6s;
    
    ${props => props.visible ?
        css`transform: translate3d(0);`
        :
        css`transform: translate3d(0px,70px,0px);`
    }
   
`;

const BSContent = styled.div`
    width: 100%;
    background-color: ${colors.dark};
    display: flex;
    flex-direction: column;
    height: 385px;
    transition: 0.4s;
    transition-delay: 0.6s;
    ${props => props.visible ?
        css`transform: translate3d(0);`
        :
        css`transform: translate3d(0px,70px,0px);`
    }
`;

const SiteSelector = styled.div`
  position: relative;
  margin-left: 20px;
  margin-top: 64px;
`;

const ButtonMenu = styled.button`
  height: 40px;
  width: 40px;
  margin-left: 10px;
`;

const SmallTitle = styled.span`
  position: fixed;
  bottom: ${props => props.visible? 10 : -50}px;
  right: 0;
  color: ${colors.background};
  margin-right: 20px;
  font-size: 24px;
  font-weight: 800;
  
  transition-duration: 0.4s;
  transition-delay: ${props => props.visible? 0.2 : 0}s;
`;

const AboutButton = styled.button`
  position: fixed;
  height: 40px;
  background-color: transparent;
  border: none;
  bottom: ${props => props.visible? 10 : -50}px;
  right: 0;
  color: ${colors.background};
  margin-right: 20px;
  font-size: 15px;
  font-weight: 500;
  
  transition-duration: 0.4s;
  transition-delay: ${props => props.visible? 0.2 : 0}s;
`;

const SwipeIndicator = styled.div`
  margin-top: 14px;
  align-self: center;
  height: 6px;
  width: 40px;
  border-radius: 3px;
  background-color: ${colors.background};
`;

export default BottomBar;
