import React, {Component} from 'react';
import styled, {css} from "styled-components";

import {colors} from "../Assets";

class AboutView extends Component {
    render() {

        const {
            isOpen
        } = this.props;
        return (
            <MainView isOpen={isOpen}>
                <Circle isOpen={isOpen}/>
                <TextAbout isOpen={isOpen}>
                    <P className={'gela'}>Calvini est un moteur de recherche, qui permet d’effectuer des requêtes parmi les articles de
                    plusieurs blogs chrétiens évangéliques qui partagent une ligne théologique similaire:
                        <Link href="https://evangile21.thegospelcoalition.org/" target="_blank"> Évangile 21</Link>,
                        <Link href="https://www.leboncombat.fr" target="_blank"> Le Bon Combat</Link>,
                        <Link href="https://www.larebellution.com/" target="_blank"> La Rébellution</Link>,
                        <Link href="https://www.reveniralevangile.com/" target="_blank"> Revenir à l’Évangile</Link>,
                        <Link href="https://toutpoursagloire.com/" target="_blank"> Tout Pour Sa Gloire </Link>et
                        <Link href="https://www.unherautdansle.net/" target="_blank"> Un Héraut dans le Net</Link>.</P>
                    <P className={'gela'}>Bien que nous partagions la même sensibilité, chaque auteur de chaque site est responsable de ses écrits.</P>
                    <P className={'gela'}>Sur le plan technique, Calvini fonctionne avec l’API de Google, l’affichage des résultats ne dépend donc pas du moteur en lui-même.</P>
                </TextAbout>
                <ContactView>
                    <ContactRow className={'gela'}>Mascotte: <Link href="https://www.arnokorper.com/" target="_blank">www.arnokorper.com</Link></ContactRow>
                    <ContactRow className={'gela'}>Développement du site: <Link href="mailto: david.perrey@gmail.com">david.perrey@gmail.com</Link></ContactRow>
                </ContactView>
            </MainView>
        );
    }
}

const MainView = styled.div`
    z-index: 998;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    visibility: ${props => props.isOpen? 'visible' : 'hidden'};
`;

const Circle = styled.div`
    background-color: ${colors.dark};
    position: fixed;
    bottom: -255vh;
    left: -255vh;
    height: 500vh;
    width: 500vh;
    border-radius: 250vh;
    
    ${props => props.isOpen ?
        css`transform: scale(1);`
        :
        css`transform: scale(0.01);`
    };
    transition: 0.7s;
`;

const TextAbout = styled.div`
    position: relative;
    width: 40%;
    transition-duration: 0.6s;
    transition-property: opacity;
    opacity: ${props => props.isOpen ? 1 : 0};
`;

const P = styled.p`
    font-weight: 500;
    line-height: 150%;
    font-size: 19px;
    color: ${colors.background};
`;

const Link = styled.a`
    color: ${colors.primary};
    cursor: pointer;
`;

const ContactView = styled.div`
    position:relative;
    margin-top: 32px;
    width: 40%;
`;

const ContactRow = styled.p`
    font-weight: 500;
    line-height: 150%;
    font-size: 19px;
    color: ${colors.background};
    margin: 0;
`;

export default AboutView;
