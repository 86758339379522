import React, {Component} from 'react';
import styled, {css} from "styled-components";

import {colors} from "../../Assets";
import searchIcnDarkSrc from '../../Assets/search_dark.svg';
import functions from "../../Utils/functions";

class SearchHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            cursorPosition: 0,
            focused: false,
        };
        this.submitAction = this.props.submitAction;
    }

    componentDidMount() {
        let textSize = functions.get_tex_size(this.props.firstQuery, "400 15px Inter");
        this.setState({
            query: this.props.firstQuery,
            cursorPosition: textSize.width <= 505 ? textSize.width : this.state.cursorPosition,
        });
    }

    _handleChange(event) {
        let textSize = functions.get_tex_size(event.target.value, "500 17px Inter");
        this.setState({
            query: event.target.value,
            cursorPosition: textSize.width <= 505 ? textSize.width : this.state.cursorPosition,
        });
    }
    _handleSubmit(event) {
        event.preventDefault();
        document.getElementById('header-input').blur();
        this.submitAction(this.state.query);
    }
    render() {

        const {
            visible
        } = this.props;
        return (
            <MainContainer visible={visible}>
                <SearchIcn src={searchIcnDarkSrc}/>

                <Form onSubmit={this._handleSubmit.bind(this)}>
                    <TextInput
                        id={'header-input'}
                        type="text"
                        value={this.state.query}
                        onFocus={ () => this.setState({focused: true}) }
                        onBlur={ () => this.setState({focused: false})}
                        onChange={this._handleChange.bind(this)}/>
                    <Cursor
                        focus={this.state.focused}
                        position={this.state.cursorPosition}/>
                </Form>
            </MainContainer>
        );
    }
}

const MainContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    background-color: ${colors.background};
    
    transition: 0.4s;
    
    ${props => props.visible ?
        css`transform: translate3d(0px,0px,0px);`
        :
        css`transform: translate3d(0px,-70px,0px);`
    }
    
    ${css`box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.6);`};

`;

const Form = styled.form`
  width: calc(100% - 100px);
  margin-left: 12px;
  flex-direction: column;
  justify-content: center;
`;

const TextInput = styled.input`
  height: 40px;
  width: 100%;
  border-width: 0px;
  appearance: none;
  color: transparent;
  background-color: transparent;
  color: ${colors.text};
  text-align: left;
  font-size: 17px;
  font-family: Inter;
  font-weight: 500;
  ::selection{
    background: ${colors.primary+'66'};
  }
`;

const Cursor = styled.div`
  // position: absolute;
  // margin-left: ${p=>p.position+2}px;
  // height: 18px;
  // width: 3px;
  // top: 22px;
  // background-color: ${colors.primary};
  // opacity: 1;
  // animation: blink 1s linear infinite;
  // visibility: ${props => props.focus? 'visible' : 'hidden'};
`;

const SearchIcn = styled.img` 
  height: 26px;
  width: 26px;
  margin-left: 20px;
`;

export default SearchHeader;
