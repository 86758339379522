import React, {Component} from 'react';
import styled, {css} from "styled-components";
import {colors} from '../Assets';

import searchIcnSrc from '../Assets/search_dark_2.svg';


class BigSearchButton extends Component {


    render() {
        const {
            size,
            action
        } = this.props;
        return (
            <Container className={'bigButton'} size={size} onClick={action}>
                <SearchIcn src={searchIcnSrc}/>
            </Container>
        );
    }
}

const Container = styled.button`
  background-color: transparent;
  position: absolute;
  right: 0px;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  margin-right: 20px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: ${props => props.size/2}px;
  
  transition: 0.3s;
  
  &:active{
    transform: scale(0.8);
  };
`;

const SearchIcn = styled.img`
  height: 50%;
  width: 50%;
  cursor: pointer;
`;

export default BigSearchButton;
