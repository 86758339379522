import React, {Component} from 'react';
import styled from "styled-components";

import { BlockReserveLoading } from 'react-loadingg';
import MobileCard from "./MobileCard";
import {colors} from "../../Assets";

class MobileList extends Component {

    _coverExist(item) {
        if(item.pagemap !== undefined){
            if(item.pagemap.cse_thumbnail !== undefined){
                return true
            }
        }
        return false;
    }

    render() {

        const {
            items,
            listState,
        } = this.props;

        return (
            <MainContainer>
                {
                    (listState === 3 || listState === 2) &&
                    <BlockReserveLoading color={colors.primary} size={'large'} style={{top: '30%'}}/>
                }
                {
                    listState === 1 &&
                    <List>
                        <Space height={20}/>
                        {items.map((item, index) => (
                            <MobileCard
                                key={index}
                                index={index}
                                title={item.title}
                                domain={item.displayLink}
                                link={item.link}
                                snippet={item.snippet}
                                coverSrc={this._coverExist(item) ? item.pagemap.cse_thumbnail[0].src : '' }
                            />
                        ))}
                        {this.props.pagesComponent()}
                    </List>
                }
                {
                    listState === 5 &&
                    <OupsView>
                        <OupsText className={'gela'}>... aucun résultat trouvé avec les termes de la recherche </OupsText>
                    </OupsView>
                }
            </MainContainer>
        );
    }
}

const MainContainer = styled.div`
    position: fixed;
    top: 60px;
    width: 100vw;
    height:calc(100% - 120px);
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
`;

const List = styled.div`
    position: relative;
    top: 0;
    left: 0;
`;

const Space = styled.div`
  height: ${props => props.height}px;
`;

const OupsView = styled.div`
    height: 100vh;
    display: flex;
    justify-content: left;
    align-items: center;
`;

const OupsText = styled.p`
  color: ${colors.text};
  font-size: 22px;
  margin: 30px;
`;

export default MobileList;
