import React, {Component} from 'react';
import styled from "styled-components";
import SnippetCard from "./SnippetCard";

import {colors} from '../Assets';

class ArticleCard extends Component {


    constructor(props) {
        super(props);
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.state = {
            isHovering: false,
            x: 0,
            y: 0,
        };
    }

    handleMouseHover(e) {
        this.setState(this.toggleHoverState);
        this.setState({ x: e.screenX, y: e.screenY });
    }

    toggleHoverState(state) {
        return {
            isHovering: !state.isHovering,
        };
    }

    render() {

        const {
            title,
            domain,
            link,
            snippet,
            coverSrc,
        } = this.props;

        return (
            <Card>

                {/*{this.state.isHovering &&*/}
                {/*<SnippetCard*/}
                {/*    snippet={snippet}*/}
                {/*    coverSrc={coverSrc}*/}
                {/*    mousePos={this.state.y}/>*/}
                {/*}*/}
                    <Title
                        className={'gela'}
                        href={link}
                        target="_blank"
                        onMouseEnter={this.handleMouseHover}
                        onMouseLeave={this.handleMouseHover}>
                        {title}
                    </Title>
                <Url>{domain.toUpperCase()}</Url>
            </Card>
        );
    }
}

const Card = styled.div`
  overflow: visible;
  max-width: 600px;
  margin-top: 20px;
`;

const Title = styled.a`
  text-align: inherit;
  font-weight: 500;
  font-size: 21px;
  margin-top: 20px;
  margin-bottom: 0px;

  ${Card}:hover & {
    color: ${colors.primary};
  };
`;

const Url = styled.p`
  text-align: left;
  font-weight: 500;
  color: ${colors.text};
  letter-spacing: 1.29999995231628px;
  opacity: 0.4;
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 20px;
`;

export default ArticleCard;
