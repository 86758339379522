import React, {Component} from 'react';
import styled, {css} from "styled-components";
import { BlockReserveLoading } from 'react-loadingg';

import ArticleCard from "./ArticleCard";

// internal
import {colors} from '../Assets';
import  functions from '../Utils/functions'

class ListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            x: 0,
            y: 0,
        }
    }

    _onMouseMove(e) {
        this.setState({ x: e.screenX, y: e.screenY });
    }

   _getCSS(listState) {
        switch(listState) {
            case 0: // liste not visible
                return css`transform: translate3d(0); opacity: 0;`;break;
            case 1: // liste visible
                return css`transform: translate3d(0); opacity: 1; transition: 0.5s`;break;
            case 2:  // new list loading
                return css`transform: translate3d(0vw,-200px,0px); opacity: 0; transition: 0.5s`;break;
            case 3:  // new list loaded
                return css`transform: translate3d(0vw,200px,0px); opacity: 0; transition: 0.5s`;break;
            default: break;
        }
    }

    _coverExist(item) {
        if(item.pagemap !== undefined){
            if(item.pagemap.cse_thumbnail !== undefined){
                return true
            }
        }
        return false;
    }

    render() {

        const {
            listState,
            items,
            pagesComponent
        } = this.props;

        return (
            <MainView
                listState={listState}
                className={'scroll-list'}>
                {
                    listState == 2 && <BlockReserveLoading style={loadingStyle} color={colors.primary} size={'large'}/>
                }
                {   listState != 5 && items.length > 0 &&
                    <List animation={this._getCSS(listState)}>
                        {items.map((item, index) => (
                            <ArticleCard
                                key={index}
                                index={index}
                                title={item.title}
                                domain={functions.betterDisplayLink(item.displayLink)}
                                link={item.link}
                                snippet={item.snippet}
                                coverSrc={this._coverExist(item) ? item.pagemap.cse_thumbnail[0].src : '' }
                            />
                        ))}
                        {this.props.pagesComponent()}
                    </List>
                }
                {
                    listState === 5 &&
                    <OupsView>
                        <OupsText>... aucun résultat trouvé avec les termes de la recherche </OupsText>
                    </OupsView>
                }

            </MainView>
        );
    }
}

const loadingStyle = {
    position: 'absolute',
    left: '850px',
    top: '40%'
};

const MainView = styled.div`
    height: 100vh;
    ${
        props => props.listState === 0 
        ?
            css`width: 0px; opacity: 0;`
        :
            css`width: calc(100vw - 500px); opacity: 1;`
    };  
    transition: 0.8s;   
    overflow-y: scroll;  
    z-index: 99;
`;

const List = styled.div`
    padding-top: 8vh;
    margin-left: 32px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: visible;
    ${props => props.animation};
`;


const OupsView = styled.div`
    height: 100vh;
    display: flex;
    justify-content: left;
    align-items: center;
`;

const OupsText = styled.p`
  color: ${colors.text};
  font-family: Gelasio, Serif;
  font-size: 22px;
`


export default ListView;
