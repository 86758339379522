import React, {Component} from 'react';
import styled from "styled-components";

import {colors} from '../Assets/';
import BigSearchButton from "./BigSearchButton";
import searchIcnSrc from "../Assets/search.svg";
import searchDarkIcnSrc from "../Assets/search_dark_2.svg";

class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            bigText: '',
            cursorPosition: 0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.submitAction = this.props.submitAction;
        this.onTextChange = this.props.onTextChange;
    }

    get_tex_size(txt, font) {
        this.element = document.createElement('canvas');
        this.context = this.element.getContext("2d");
        this.context.font = font;
        var tsize = {'width':this.context.measureText(txt).width, 'height':parseInt(this.context.font)};
        return tsize;
    }

    handleChange(event) {
        let bigText = event.target.value === '' ? 'rechercher' : event.target.value;
        var tsize = this.get_tex_size(event.target.value, "700 21px Inter");
        this.setState({
            query: event.target.value,
            bigText: bigText,
            cursorPosition: tsize.width <= 388 ? tsize.width : this.state.cursorPosition,
        });
        // this.onTextChange(event.target.value === '' ? 'rechercher' : event.target.value);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.submitAction(this.state.query);
    }

    componentDidUpdate(prevProps, prevState) {
        this._input.focus();
    }

    render() {

        const {onSubmit, listState} = this.props;

        return (
            <MainContainer isBig={listState}>
                <BigText
                    isBig={listState}
                    className={'inter'}>{this.state.bigText.toUpperCase()}</BigText>
                <Form onSubmit={this.handleSubmit}>
                    <TextInput
                        type="text"
                        isBig={listState}
                        ref={c => (this._input = c)}
                        autofocus={'true'}
                        placeholder={'rechercher'}
                        value={this.state.query}
                        onChange={this.handleChange}
                        className={'inter'}/>
                    <Cursor position={this.state.cursorPosition}/>
                    {/*<input type="submit" value="Envoyer"/>*/}
                    <SearchButton
                        onClick={this.handleSubmit}
                        isBig={listState}>
                        <SearchIcn src={searchDarkIcnSrc}/>
                    </SearchButton>
                </Form>
            </MainContainer>
        );
    }
}

const MainContainer = styled.div`
  position: relative;
  height: 100px;
  
  margin-top: 4vh;
  margin-bottom: 2vh;
  margin-left: ${props => props.isBig ? 0 : 4}vw;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.6s;
`;

const Form = styled.form`
  position:absolute;
  top: 25px;
  width: 400px;
  overflow: visible;
  height: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextInput = styled.input`
  position:relative;
  height: 50px;
  width: 388px;
  border-width: 0px;
  border-bottom: 2px solid ${props => props.isBig? colors.text : colors.text};;
  appearance: none;
  color: transparent;
  background-color: transparent;
  text-shadow: 0 0 0 ${props => props.isBig? colors.text : colors.text};
  text-align: left;
  font-size: 21px;
  font-weight: 700;
  ::selection{
    background: ${colors.primary+'66'};
  }
  ::placeholder {
      opacity: 0.42;
      color: ${colors.text};
      font-size: 21px;
      font-weight: 500;
  }
  z-index: 90;
`;

const Cursor = styled.div`
  position: absolute;
  margin-left: ${p=>p.position+2}px;
  height: 25px;
  width: 4px;
  top: 12px;
  background-color: ${colors.primary};
  opacity: 0.8;
  animation: blink 1s linear infinite;
`;

const BigText = styled.p`
  text-align: left;
  font-weight: 900;
  font-style: normal;
  font-size: 150px;
  margin-left: -25px;
  color: ${colors.primary};
  opacity: 0.0;
  user-select: none;
`

const SearchButton = styled.div`
  position: absolute;
  top: -10px;
  right: ${props => props.isBig? -80 : -10 }px;
  height: ${props => props.isBig? 80 : 60 }px;
  width: ${props => props.isBig?  80 : 60 }px;
  border-radius: 40px;
  
  background-color: transparent;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  z-index: 99;
  
  &:hover{
    transform: scale(0.9);
  };
  
  transition: 0.4s;
  cursor: pointer;
`;

const SearchIcn = styled.img`
  height: 50%;
  width: 50%;
  cursor: pointer;
`;

export default SearchBar;
